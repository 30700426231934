import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { Roles } from "../../types/types";
import style from "./Header.module.scss";

interface GenericNavbarLinkProps {
  linkTo: string;
  displayText: string;
}

const GenericNavBarButton = ({
  linkTo,
  displayText,
  liClassName,
  aActiveClassName,
}: BottomProps & GenericNavbarLinkProps): ReactElement => (
  <li key={linkTo} className={liClassName}>
    <NavLink
      activeClassName={aActiveClassName}
      to={linkTo}
    >
      <span>{displayText}</span>
    </NavLink>
  </li>
);

export function RefreshingNavBarButton({
  linkTo,
  displayText,
  liClassName,
  aActiveClassName,
}: BottomProps & GenericNavbarLinkProps): ReactElement {
  const location = useLocation();
  const isActive = (location.pathname === linkTo);

  return (
    <li key={linkTo} className={liClassName}>
      <a
        className={isActive ? aActiveClassName : undefined}
        href={linkTo}
        aria-current={isActive ? 'page' : undefined}
      >
        <span>{displayText}</span>
      </a>
    </li>
  );
};

const UsersButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <GenericNavBarButton
      linkTo="/dashboard/admin/users"
      displayText={t("Users")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};
const ActivityButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <GenericNavBarButton
      linkTo="/dashboard/admin/activity-report"
      displayText={t("Activity Report")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};
const SettingsButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <GenericNavBarButton
      linkTo="/dashboard/admin/settings"
      displayText={t("Settings")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};
const InstallationButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();
  const userContext = useUser();
  return (
    <GenericNavBarButton
      linkTo={`/dashboard/admin/user-installations/${
        userContext?.user?.role === Roles.Admin ? "company" : "users"
      }`}
      displayText={t("User Installations")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};

const SupportButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <GenericNavBarButton
      linkTo="/dashboard/admin/support"
      displayText={t("Support")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};

const RestartButton = ({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <GenericNavBarButton
      linkTo="/dashboard/admin/restart"
      displayText={t("Restart")}
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
  );
};

/* Component */
export function HeaderBottomComplete(): ReactElement {
  const userContext = useUser();
  const location = useLocation();

  if (userContext.user === null) {
    return <div />;
  }

  if (location.pathname.startsWith("/dashboard/admin")) {
    return (
      <AdminPanelOptionsComplete
        liClassName={`nav-item nav-link ${style["nav-link-bottom"]}`}
        aActiveClassName={style.active}
      />
    );
  }
  return (
    <DashboardMainOptions liClassName={`nav-item nav-link ${style["nav-link-bottom"]}`} aActiveClassName={style.active} />
  );
}

export function AdminPanelOptionsComplete({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement {
  const userContext = useUser();
  if (userContext.user === null) {
    return <div />;
  }

  switch (userContext.user.role) {
    case Roles.Admin:
      return (
        <AdminAdminPanelOptions
          liClassName={liClassName}
          aActiveClassName={aActiveClassName}
        />
      );
    case Roles.CompanyUser:
      return (
        <CompanyAdminPanelOptions
          liClassName={liClassName}
          aActiveClassName={aActiveClassName}
        />
      );
    case Roles.Supervisor:
      return (
        <SupervisorAdminPanelOptions
          liClassName={liClassName}
          aActiveClassName={aActiveClassName}
        />
      );
    case Roles.User:
      return <div />;
    default:
      return <div />;
  }
}

function AdminAdminPanelOptions({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement {
  return (
    <>
      <UsersButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <ActivityButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <SettingsButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <InstallationButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <SupportButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <RestartButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
    </>
  );
}

function CompanyAdminPanelOptions({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement {
  return (
    <>
      <UsersButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      {/* will see users in his company */}
      <InstallationButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <SupportButton
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
    </>
  );
}

function SupervisorAdminPanelOptions({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement {
  /* will see users assigned to him */
  return (
    <>
    <UsersButton
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
    />
    <InstallationButton
      liClassName={liClassName}
      aActiveClassName={aActiveClassName}
      />
    </>
  );
}

interface BottomProps {
  liClassName: string;
  aActiveClassName: string;
}

export function DashboardMainOptions({
  liClassName,
  aActiveClassName,
}: BottomProps): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <RefreshingNavBarButton
        linkTo="/dashboard/list/active"
        displayText={t("List view")}
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <GenericNavBarButton
        linkTo="/dashboard/alarm/level"
        displayText={t("Alarms")}
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
      <RefreshingNavBarButton
        linkTo="/dashboard/list/archive"
        displayText={t("Archive")}
        liClassName={liClassName}
        aActiveClassName={aActiveClassName}
      />
    </>
  );
}
